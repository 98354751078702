<template>
  <div class="video-wall-container">
    <div class="video-wall-wrapper">
      <div class="row" v-if="errorOccurs !== null">
        <div class="col-12 px-0">
          <internalServerError v-if="errorOccurs === 500" />
          <unauthorizedError v-if="errorOccurs === 401" />
        </div>
      </div>
      <div class="row" v-if="errorOccurs === null">
        <div class="col-12 p-0">
          <p class="wall-header">{{ videoRecognitionTitle }}</p>
        </div>
        <div class="col-12 p-0" v-if="!noNominationsToDisplay">
          <div class="video-player" v-if="currentSelectedVideo !== '' && currentSelectedVideo !== undefined && currentSelectedVideo !== null">
            <VideoPlayer :startAutoPlay="currentIndex > 0" :videoSource="currentSelectedVideo" @playNextVideo="playNextVideo" @playPreviousVideo="playPreviousVideo" />
          </div>
        </div>
        <div class="col-12 p-0" v-if="!noNominationsToDisplay && videolist.length > 0">
          <div class="video-list-scroll-header">
            <p class="next-header">{{ upNextMessage }}</p>
            <div class="wall-next-previous-buttons d-flex">
              <button class="btn btn-primary mr-2 d-flex justify-content-center align-items-center" @click.prevent="scrollToSpecificWidthPrevious">
                <i class="fa fa-angle-left"></i>
              </button>
              <button
                class="btn btn-primary d-flex justify-content-center align-items-center"
                @click.prevent="scrollToSpecificWidthNext"
                :disabled="isApiCalling"
                >
                <i class="fa fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <div class="wall-video-list-horizontal-scroll" v-if="!noNominationsToDisplay" id="scroller" @scroll="scrollHandler">
            <div class="video-cards"  v-for="(data, index) in videolist" :key="index" @click.prevent="selectedVideoToPlayHandler(data, index)">
                <video ascpe preload playsinline :class="currentIndex == index ? 'border border-primary' : ''">
                  <source :src="data.attachmentFilename + '#t=0.1'">
                </video>
                <div class="video-duration-tag">
                  {{getVideoLength(data.videoLength)}}
                </div>
                <div>
                  <p class="video-cards-title m-0">
                    From {{data.awarder.fName}} {{data.awarder.lName}}
                  </p>
                  <p class="video-cards-subtitle m-0">{{filterDate(data.createdAt)}}</p>
                </div>
            </div>
            <div id="loading-card">
              <div
                v-if="videolist.length > 0 && !reachedEnd"
                class="video-cards d-flex justify-content-center align-items-center loader-card">
                  <div class="search-loader">Loading...</div>
              </div>
            </div>
          </div>
          <div class="no-videos-image" v-else></div>
        </div>
        <div class="col-12 py-2 px-0" v-if="noNominationsToDisplay">
          <p class="error-title">{{ noVideoTitle }}</p>
          <p class="error-subtitle m-0">
            {{ noAwardMessage1 }}
          </p>
          <p class="error-subtitle m-0">
            {{ noAwardMessage2 }}
          </p>
          <button @click="createNewNomination" class="btn error-button rounded-sm btn-primary mt-3">
            {{ createNewNominationMessage }}
          </button>
        </div>
      </div>
      <app-loader v-if="showLoader"></app-loader>
    </div>
  </div>
</template>
<script>
import VideoPlayer from './videoplayer'
import NominationApi from '@/service/api.js'
import internalServerError from '@/components/500'
import unauthorizedError from '@/components/401'
import moment from 'moment'
import eventEmit from '@/mixin/index.js'
import getTranslation from '@/mixin/translations.js'
import loader from '@/components/loader'

export default {
  components: {
    VideoPlayer,
    unauthorizedError,
    internalServerError,
    'app-loader': loader,
  },
  mixins: [eventEmit, getTranslation],
  data () {
    return {
      videolist: [],
      videoListWithNullValues: [],
      currentSelectedVideo: null,
      currentIndex: 0,
      reachEndOfScroll: false,
      page: 1,
      length: 10,
      reachedEnd: false,
      noNominationsToDisplay: false,
      totalPages: null,
      totalItems: null,
      errorOccurs: null,
      startAutoPlay: false,
      isApiCalling: false,
      scrollHeightCheckInterval: null,
      documentScrollHeight: null,
      showLoader: true,
      continueSessionInterval: null
    }
  },
  computed: {
    noVideoTitle () {
      return this.getTranslations("oops") || this.$tc("message.videoErrors", 0)
    },
    videoRecognitionTitle () {
      return this.getTranslations("videoRecognitions") || this.$t("message.videoWallHeader")
    },
    createNewNominationMessage () {
      return this.getTranslations("createNominations") || this.$tc("message.videoWallCreateButton")
    },
    noAwardMessage1 () {
      return this.getTranslations("noVideosToShow") || this.$tc("message.videoErrors", 1)
    },
    noAwardMessage2 () {
      return this.getTranslations("checkBackSoon") || this.$tc("message.videoErrors", 2)
    },
    upNextMessage () {
      return this.getTranslations("upNext") || this.$t("message.videoListHeader")
    }
  },
  created () {
    this.scrollHeightCheckInterval = setInterval(() => {
      if (this.documentScrollHeight !== document.body.scrollHeight) {
        this.documentScrollHeight = document.body.scrollHeight
        this.sendResizeContent(false, false)
      }
    }, 500)
  },
  beforeDestroy () {
    clearInterval(this.scrollHeightCheckInterval)
    clearInterval(this.continueSessionInterval)
  },
  mounted () {
    this.requestInitialPostMessage()
    this.length = Number(process.env.VUE_APP_VIDEO_LIST_LIMIT) || 10
    this.getVideoList()
    this.sendResizeContent(false, true)
    const continueSessionFreq = this.$store.getters.continueSessionIntervalInSecs
    if (continueSessionFreq) {
      this.continueSessionInterval = setInterval(() => {
        this.continueSession()
      }, continueSessionFreq * 1000)
    }
  },
  watch: {
    reachEndOfScroll: {
      handler () {
        this.getVideoList()
      }
    }
  },
  methods: {
    filterDate (date) {
      return moment(date).format('MMM DD, YYYY')
    },
    createNewNomination () {
      this.$router.push({name: 'AwardStepper'})
    },
    selectedVideoToPlayHandler (data, index) {
      this.currentIndex = index
      this.currentSelectedVideo = data.attachmentFilename
    },
    async continueSession () {
      this.showLoader = true
      const cookieExpiresIn = this.$store.getters.continueSessionIntervalInSecs
      NominationApi.continueSession(cookieExpiresIn).then(() => {
        this.showLoader = false
      }).catch(error => {
        this.showLoader = false
        if(error.response?.status == 401){
          this.tab = 401;
        } else if (error.response?.status === 409) {
          this.openToastNotificationForMessage(error.response.data.error.message, 'error')
        } else{
          this.tab = 500;
        }
      })
    },
    // handling scroll event
    scrollHandler () {
      this.reachEndOfScroll = this.bottomVisible()
    },
    // infinite scrolling
    bottomVisible() {
      let loaderElem = document.getElementById("loading-card")
      let elem = document.getElementById("scroller")
      const scrollX = elem.scrollLeft
      const visible = elem.offsetWidth
      const elemWidth = elem.scrollWidth
      if (!loaderElem) return false
      const bottomOfPage = visible + scrollX >= loaderElem.offsetLeft
      return (bottomOfPage || elemWidth < visible) && !this.isApiCalling && !this.reachedEnd
    },
    // Horizontal scroll to last video visible on screen
    scrollToSpecificWidthNext () {
      let elem = document.getElementById("scroller")
      let loaderElem = document.getElementById("loading-card")
      let videoCards = document.getElementsByClassName("video-cards")
      // here 18 is margin
      let cardWidth = videoCards[0].offsetWidth + 18
      const visible = elem.offsetWidth
      const scrollX = elem.scrollLeft
      if (!loaderElem) return
      if (scrollX > loaderElem.offsetLeft) return
      let toatlVisivle = Math.floor((visible + scrollX) / cardWidth)
      elem.scrollLeft = toatlVisivle * cardWidth
    },
    // Horizontal scroll to first video visible on screen
    scrollToSpecificWidthPrevious () {
      let elem = document.getElementById("scroller")
      let videoCards = document.getElementsByClassName("video-cards")
      let cardWidth = videoCards[0].offsetWidth
      // 18 here is margin
      const scrollX = elem.scrollLeft
      const visible = elem.offsetWidth
      let lastIndexOfElem = Math.floor((scrollX / (cardWidth + 18)))
      elem.scrollLeft = (lastIndexOfElem * (cardWidth + 18)) - (visible - cardWidth)
    },
    getVideoLength (length) {
      if (length === null) return ''
      let minute = Math.floor(length / 60)
      let seconds = Math.floor(length % 60)
      return `${minute}.${seconds < 10 ? '0' : ''}${seconds}`
    },
    playNextVideo () {
      if (this.currentIndex + 1 === this.totalItems) return
      this.currentSelectedVideo = this.videolist[this.currentIndex + 1].attachmentFilename
      this.currentIndex +=1
    },
    playPreviousVideo () {
      if (this.currentIndex == 0) return
      this.currentSelectedVideo = this.videolist[this.currentIndex - 1].attachmentFilename
      this.currentIndex -= 1
    },
    async getVideoList (state) {
      // prevent stack of api calls
      if (this.isApiCalling) return
      this.isApiCalling = true
      // Reached end of pagination
      if (this.totalPages !== null && this.page + 1 > this.totalPages) {
        this.reachedEnd = true
        this.isApiCalling = false
        return
      }
      this.isApiCalling = true
      NominationApi.getAllNomination(this.page, this.length)
      .then(({data}) => {
        this.totalPages = data.totalPages
        this.totalItems = data.totalItems
        this.showLoader = false
        if (data.data !== null) {
          if (data.data.length == 0 && this.page == 1) {
            this.noNominationsToDisplay = true
            this.isApiCalling = false
            return
          }
          this.page = Number(data.page) + 1
          this.noNominationsToDisplay = false
          data.data.forEach((val) => {
            if (val.attachmentFilename.indexOf("null") > -1) {
              this.videoListWithNullValues.push(val)
            } else {
              this.videolist.push(val)
            }
          })
          // Hiding loader once end is reached
          if (this.totalItems <= (this.videolist.length + this.videoListWithNullValues.length) || data.data.length === 0) {
            this.isApiCalling = false
            this.reachedEnd = true
          }
          if (this.currentSelectedVideo === null && this.videolist) {
            this.currentSelectedVideo = this.videolist[0].attachmentFilename
          }
        }
        if (state !== undefined) state.loaded()
        this.isApiCalling = false
      })
      .catch(error => {
        this.showLoader = false
        this.isApiCalling = false
        if(error.response.status == 401){
          this.errorOccurs = 401;
        }else{
          this.errorOccurs = 500;
        }
        if (state !== undefined) state.complete()
      })
    }
  }
}
</script>
<style scoped>
.video-wall-container {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;
  background: var(--bg-color);
}
.video-wall-wrapper{
  max-width: 815px;
  width: 100%;
}
.wall-next-previous-buttons button {
  border-radius: 50%;
  outline: none;
  width: 25px;
  height: 25px;
  position: relative;
}
.wall-next-previous-buttons button i {
  position: absolute;
  left: 50%;
  font-size: 1rem;
  bottom: 50%;
  transform: translate(-50%, 50%);
}
.video-list-scroll-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.wall-video-list-horizontal-scroll{
  display: inline-flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: 100%;
  min-height: 155px;
  overflow-y: hidden;
  margin: 18px 0 0;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.video-cards {
  width: 198px;
  min-width: 198px;
  margin-right: 18px;
  border-radius: 3px;
  height: 112px;
  position: relative;
  display: block;
}
.video-cards video {
  width: 100%;
  height: 100%;
  border-radius: 3px !important;
  object-fit: cover !important;
  cursor: pointer;
  display: block !important;
}
.border {
  border-width: 2px !important;
}

/* If we want scroll bar give height some pixels here. */
::-webkit-scrollbar {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-track {
  box-shadow: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.vjs-time-tooltip {
  color: white;
}
.video-duration-tag {
  background: linear-gradient(
          rgba(0, 0, 0, 0.7),
          rgba(0, 0, 0, 0.7)
        ), transparent;
  opacity: 0.9;
  color: #ffffff;
  width: 32px;
  border-radius: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  margin: 0;
  font-size: 9px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.no-videos-image {
  background: url('../../assets/images/Layer 14.png');
  position: relative;
  width: 100%;
  background-size: cover;
  margin: 18px 0 18px;
  border-radius: 6px !important;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

/* Font css */
.wall-header {
  letter-spacing: -1px;
  color: #333333;
  opacity: 1;
  margin: 0;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.next-header {
  text-align: left;
  letter-spacing: -0.9px;
  color: #333333;
  opacity: 1;
  margin: 0;
  font-size: 18px;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.video-player{
  margin: 16px 0 23px;
}
.video-cards-subtitle {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 10px;
  color: #333333;
}

.video-cards-title {
  font-size: 12px;
  color: #333333;
  font-family: 'Montserrat-SemiBold', sans-serif;
}
.error-title{
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 18px;
  color: #333333;
}
.error-subtitle{
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 16px;
}
.error-button{
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 14px;
  padding: 12px;
}
.search-loader {
  font-size: 6px;
}
.loader-card {
  min-width: 100px !important;
  max-width: 100px;
}
</style>