<template>
  <video ref="videoPlayer" preload="metadata" class="video-js">
  </video>
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
export default {
    name: "VideoPlayer",
    props: ['videoSource', 'startAutoPlay'],
    data() {
        return {
          player: null,
          options: {
            autoplay: false,
            controls: true,
            bigPlayButton: false,
            progressColor: 'blue',
            changingSrc_: true,
            currentTimeDisplay: false,
            aspectRatio: '16:9',
            durationDisplay:false,
            inactivityTimeout: 0,
            muted: true,
            fluid: true,
            controlBar: {
              volumePanel: true,
              pictureInPictureToggle: false
            },
            sources: [
              {
                src:
                  "",
                  type: "video/mp4"
              }
            ]
          }
        }
    },
    watch: {
      videoSource : {
        handler () {
          this.player.src(this.videoSource)
        }
      },
      startAutoPlay: {
        handler () {
          if (this.startAutoPlay === true) {
            this.player.autoplay(true)
          }
        }
      }
    },
    mounted() {
      console.log("this.videoSource", this.videoSource)
      this.options.sources[0].src = this.videoSource
      let self = this;
      // once player is ready adding new buttons
      this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {})
      if (this.player !== null) {
        console.log(this.player)
        // Adding new button for next and previous video
        this.player.controlBar.addChild('button', {
        'el':videojs.createEl('button', {}, { className:  'vjs-hd-button vjs-control',
              'role': 'button', id: 'previousButton'})
        });
        // adding text inside of button
        document.getElementById('previousButton').innerHTML = `<i class="fa fa-step-backward text-white"></i>`
        // adding an event listener
        document.getElementById('previousButton').addEventListener("click", self.playPreviousVideo)

        this.player.controlBar.addChild('button', {
        'el':videojs.createEl('button', {}, { className:  'vjs-hd-button vjs-control',
              'role': 'button', id: 'nextButton'})
        });
        document.getElementById('nextButton').addEventListener("click", self.playNextVideo)
        document.getElementById('nextButton').innerHTML = `<i class="fa fa-step-forward text-white"></i>`

        // adding / between current and deprecated time
        let x = document.getElementsByClassName("vjs-current-time")
        x[0].append('   /')

        let progressBar = document.getElementsByClassName("vjs-play-progress")
        progressBar[0].className = progressBar[0].className + " bg-primary text-white "
      }
    },
    methods: {
      playNextVideo () {
        this.$emit('playNextVideo', null)
      },
      playPreviousVideo () {
        this.$emit('playPreviousVideo', null)
      },
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose()
      }
    }
}
</script>
<style lang="scss">
@import '../../assets/styles/style.css';
.vjs-progress-control{
  position: absolute !important;
  width: calc(100% - 50px - 75px) !important;
  bottom: 20px !important;
  margin-left: 55px !important;
}
.video-js .vjs-play-control{
  font-size: 25px !important;
  margin-top: -35px !important;
  margin-left: -20px !important;
  border-radius: 6px !important;
}
.vjs-fullscreen-control {
  position: absolute !important;
  right: 10px !important;
  background: red;
}
.vjs-control-bar {
  background: transparent !important;
}
#vjs_video_3 {
  width: 100% !important;
  max-width: 815px !important;
  border-radius: 6px !important;
  max-height: 500px;
}
.video-js .vjs-duration {
  position: absolute !important;
  right: 0 !important;
  text-align: left;
  width: 38px;
  bottom: 20px !important;
  font-family: 'Montserrat-SemiBold', sans-serif;
  padding-left: 3px !important;
  padding-right: 0 !important;
}
.video-js .vjs-current-time{
  position: absolute !important;
  right: 37px !important;
  bottom: 20px !important;
  padding-right: 0px !important;
  font-family: 'Montserrat-SemiBold', sans-serif;

}
.vjs-volume-panel{
  position: absolute !important;
  left: 105px;
}
#previousButton {
  font-size: 14px;
  cursor: pointer;
  padding: 0 7px;
  margin-left: -20px;
}

#nextButton {
  font-size: 14px;
  cursor: pointer;
  padding: 0 7px;
  margin-left: 5px;
}

.video-js .vjs-time-control{display:block;}
.video-js .vjs-remaining-time{display: none;}
.video-js .vjs-time-divider {
  display: none;
}
.vjs-fade-in,.vjs-fade-out, .vjs-default-skin.vjs-has-started, .vjs-control-bar {
  visibility: visible !important;
  opacity: 1 !important;
  transition-duration: 0s!important;
}
.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  visibility: visible;
  opacity: 1;
}
:not(.vjs-has-started) .vjs-control-bar {
  display: flex;
}
video {
  border-radius: 6px !important;
}
.vjs-big-play-button{
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  top: 50% !important;
}
</style>